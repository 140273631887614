import {
  FIRM_WARNING_NAME,
  FirmWarningTextConfig,
  FIRMWithWarnings,
  floodzoneWarningText,
} from "common-client/utils/firmInfoWarnings";
import { BannerItems } from "./__styles__/FIRMInfo";
import { RESOURCE_NAME } from "common/authorization";
import { useContext } from "react";
import { AuthContext } from "../../../Authorization/AuthContext";
import { getFormattedFIRMNames } from "common-client/utils/firms";
import { flatten, uniq } from "lodash";
import React, { Fragment, ReactElement } from "react";
import { StyledLink } from "../../../Common/__styles__/Typography";
import { GetHelpLink } from "../../../Guest/GetHelpLink";
import Banner from "../../../Common/Banner";

type PlaceholderToComponentMap = { [key: string]: ReactElement };

type RenderWarningProps = {
  splitMessage: Array<string>;
  placeholderToComponentMap: PlaceholderToComponentMap;
};

const renderFirmWarning = ({
  splitMessage,
  placeholderToComponentMap,
}: RenderWarningProps): ReactElement => {
  const firmWarning = splitMessage.map((part, index) => (
    <Fragment key={index}>
      {placeholderToComponentMap[part] ? placeholderToComponentMap[part] : part}
    </Fragment>
  ));

  return <div data-testid="firm-warning">{firmWarning}</div>;
};

const checkRequiredReplacements = ({
  requiredReplacements,
  placeholderToComponentMap,
}: {
  requiredReplacements?: Array<string>;
  placeholderToComponentMap: PlaceholderToComponentMap;
}) => {
  if (!requiredReplacements) return;

  requiredReplacements.forEach(replacement => {
    //This will catch any missing replacements if the function is called without a required one
    if (!placeholderToComponentMap[replacement]) {
      throw new Error(`Missing replacement for ${replacement}`);
    }
  });
};

const getFirmWarningText = ({
  firmWarning,
  canUpdate,
  isViewOnly,
  isGuest,
  placeholderToComponentMap,
  renderWarning,
  warningTextConfig,
}: {
  firmWarning: FIRM_WARNING_NAME;
  canUpdate?: boolean;
  isGuest?: boolean;
  isViewOnly?: boolean;
  placeholderToComponentMap: PlaceholderToComponentMap;
  renderWarning: ({
    splitMessage,
    placeholderToComponentMap,
  }: RenderWarningProps) => ReactElement;
  warningTextConfig: FirmWarningTextConfig;
}): ReactElement => {
  const { defaultMessage, canUpdateMessage, isGuestMessage, viewOnlyMessage } =
    warningTextConfig[firmWarning];

  checkRequiredReplacements({
    requiredReplacements: defaultMessage.requiredReplacements,
    placeholderToComponentMap,
  });

  let message = defaultMessage.message;

  if (canUpdate) {
    checkRequiredReplacements({
      requiredReplacements: canUpdateMessage.requiredReplacements,
      placeholderToComponentMap,
    });

    message = `${message} ${canUpdateMessage.message}`;
  } else if (isGuest && isGuestMessage) {
    checkRequiredReplacements({
      requiredReplacements: isGuestMessage.requiredReplacements,
      placeholderToComponentMap,
    });

    message = `${message} ${isGuestMessage.message}`;
  } else if (isViewOnly && viewOnlyMessage) {
    checkRequiredReplacements({
      requiredReplacements: viewOnlyMessage.requiredReplacements,
      placeholderToComponentMap,
    });

    message = `${message} ${viewOnlyMessage.message}`;
  }
  //This regex splits the default message into an array where each part is either a replacement or a string
  const regex = new RegExp(
    `(${Object.keys(placeholderToComponentMap).join("|")})`,
    "gm"
  );

  const splitMessage = message.split(regex);

  return renderWarning({ splitMessage, placeholderToComponentMap });
};

export const FirmWarningsBanner = ({
  firms,
  propertyId,
  warningTextConfig,
  title,
  additionalReplacements,
  compactList = false,
}: {
  firms: Array<
    Pick<FIRMWithWarnings, "warnings" | "isApproximateBfe" | "name" | "mscLink">
  >;
  propertyId?: string;
  warningTextConfig: FirmWarningTextConfig;
  title?: string;
  additionalReplacements?: PlaceholderToComponentMap;
  compactList?: boolean;
}) => {
  const { authorized, isGuest } = useContext(AuthContext);

  const warningNames = uniq(
    flatten(firms.map(firm => firm.warnings)).map(warning => warning.name)
  );

  const canUpdateFIRMInfo = authorized({
    resource: RESOURCE_NAME.FIRM,
    permission: "update",
  });

  const canViewFIRMInfo = authorized({
    resource: RESOURCE_NAME.FIRM,
    permission: "view",
  });

  const mscLink = firms.find(firm =>
    firm.warnings.some(
      warning => warning.name === FIRM_WARNING_NAME.PROPERTY_HAS_APPROXIMATE_BFE
    )
  )?.mscLink;

  const warningsInBanner = uniq(warningNames).map(warningName => {
    const formatedFirmNames = getFormattedFIRMNames(firms, warningName);

    const floodzoneText = floodzoneWarningText(firms);

    return getFirmWarningText({
      firmWarning: warningName,
      canUpdate: canUpdateFIRMInfo,
      isGuest,
      isViewOnly: canViewFIRMInfo && !isGuest,
      placeholderToComponentMap: {
        "{GET_HELP_LINK}": <GetHelpLink propertyId={propertyId} />,
        "{FLOODZONE_TEXT}": <>{floodzoneText}</>,
        "{FIRM_NAME}": <strong>{formatedFirmNames}</strong>,
        "{MSC_LINK}": mscLink ? (
          <StyledLink href={mscLink} target="_blank">
            Flood Insurance Study
          </StyledLink>
        ) : (
          <strong>Flood Insurance Study</strong>
        ),
        ...additionalReplacements,
      },
      renderWarning: renderFirmWarning,
      warningTextConfig,
    });
  });

  if (!warningsInBanner.length) return null;

  return (
    <Banner
      compact={true}
      warning={true}
      style={{ backgroundImage: "none", padding: "12px 16px" }}
    >
      {title && <div style={{ fontWeight: 500 }}>{title} warnings</div>}
      {warningsInBanner.map((warning, i) => (
        <BannerItems compact={compactList} key={i}>
          {warning}
        </BannerItems>
      ))}
    </Banner>
  );
};
